import React, { useEffect, useState } from "react"
import {
  AppraisalTemplateClient,
  CoreCompetencyDefinitionSetClient,
  GoalSettingDefinitionSetClient,
} from "@api"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import {
  Row,
  Col,
  Card,
  PageHeader,
  message,
  Descriptions,
  Divider,
  List,
  Table,
  Button,
} from "antd"
import Loading from "@components/Loading"
import { translate } from "@action"

const showError = msgArr => {
  for (var key in msgArr) {
    if (!msgArr.hasOwnProperty(key)) continue
    var objArr = msgArr[key]
    objArr.map(msg => message.error(msg))
  }
}

const findStep = (step, arr) => {
  let item = arr.find(item => item.step === step)
  return item.name
}

const transform = (arr, pathList) => {
  return arr.map(item => {
    return {
      title: translate(item.name, "en-US"),

      title_cn: translate(item.name, "zh-HK"),

      type:
        item.commentType === 0
          ? "Text"
          : item.commentType === 1
          ? "Datatime"
          : item.commentType === 2
          ? "Attachment"
          : " - ",

      step: findStep(item.step, pathList),
      order: item.order,
    }
  })
}

const CommentTable = ({ dataSource }) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "20%",
    },
    {
      title: "標題",
      dataIndex: "title_cn",
      width: "20%",
    },
    {
      title: "Input Type",
      dataIndex: "type",
      width: "20%",
    },
    {
      title: "Step",
      dataIndex: "step",
      width: "20%",
    },
    {
      title: "Order",
      dataIndex: "order",
      width: "20%",
    },
  ]
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
    />
  )
}

const Template = ({ record }) => {
  const sortedList = record.appraisalTemplateApprovalPath.sort(
    (a, b) => a.step - b.step
  )

  const guideline = record.isNeedGoalSetting
    ? translate(record.goalSetting?.goalSettingGuideLine, "en-US")
    : ""
  const guidelineCn = record.isNeedGoalSetting
    ? translate(record.goalSetting?.goalSettingGuideLine, "zn-HK")
    : ""
  const commentDataSource = transform(
    record.appraisalTemplateComment,
    record.appraisalTemplateApprovalPath
  )

  return (
    <Row gutter={[12, 12]} justify="center">
      <Col span={24}>
        <Descriptions layout="horizontal" bordered column={12} size="small">
          <Descriptions.Item label="Template Code" span={2}>
            {record.code}
          </Descriptions.Item>
          <Descriptions.Item label="Template Name" span={2}>
            {record.name}
          </Descriptions.Item>
          <Descriptions.Item label="Template Type" span={8}>
            {record.type === 0
              ? "Non-Managerial"
              : record.type === 1
              ? "Managerial"
              : record.type === 2
              ? "Frontline"
              : record.type === 3
              ? "360° Evaluation"
              : "N / A"}
          </Descriptions.Item>
          <Descriptions.Item
            label="Core Competencies Weighting"
            span={record.type === 3 ? 2 : 0}
          >
            {record.coreCompetencyWeighting}
          </Descriptions.Item>
          <Descriptions.Item
            label="Site KPI Weighting"
            span={record.type === 3 ? 1 : 0}
          >
            {record.siteKPIWeighting}
          </Descriptions.Item>
          <Descriptions.Item
            label="360° Evaluation Weighting"
            span={record.type === 3 ? 1 : 0}
          >
            {record.degree360Weighting}
          </Descriptions.Item>
          <Descriptions.Item
            label="360° Template"
            span={record.type === 3 ? 8 : 0}
          >
            {record.degree360Weighting > 0
              ? record.degree360TemplateName
              : " N / A"}
          </Descriptions.Item>

          <Descriptions.Item
            label="Have Goal Setting"
            span={record.type === 3 ? 2 : 0}
          >
            {record.isNeedGoalSetting ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Show Attendance Record" span={2}>
            {record.isShowAttendanceRecord ? "Yes" : "No"}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={record.type !== 3 ? 24 : 0}>
        <Card title="Appraisal Path">
          {sortedList.map((item, index) => (
            <>
              <Divider orientation="left">
                {index + 1 + ". " + item.name}
              </Divider>
              <Descriptions
                key={"Path" + index}
                layout="horizontal"
                bordered
                column={12}
                size="small"
              >
                <Descriptions.Item label="Approval Role" span={3}>
                  {item.appraisalRoleType === 0
                    ? "Immediate Supervisor"
                    : item.appraisalRoleType === 1
                    ? "Appraisee"
                    : item.appraisalRoleType === 3
                    ? "Subordinate"
                    : item.appraisalRoleType === 4
                    ? "Site Admin"
                    : item.approvalRole?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Immediate Supervisor Level" span={3}>
                  {item.appraisalRoleType === 0
                    ? item.immeidateSupervisorLevel
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Action" span={3}>
                  {item.action}
                </Descriptions.Item>
                <Descriptions.Item label="Core Competencies" span={3}>
                  {item.isNeedUseCoreCompetencies ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Remarks / Comment " span={3}>
                  {item.isNeedReviewComment ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Batch Approve" span={3}>
                  {item.hasBatchApprove ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Return" span={3}>
                  {item.isHaveReturn ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Skip Return" span={3}>
                  {item.isReturnSkipped ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Can Recall" span={3}>
                  {item.isHaveRecall ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Step to Return" span={3}>
                  {item.isHaveReturn
                    ? findStep(item.stepToReturn, sortedList)
                    : "N / A"}
                </Descriptions.Item>
              </Descriptions>
            </>
          ))}
        </Card>
      </Col>

      <Col span={24}>
        <Card title="Rating Scale">
          <div
            dangerouslySetInnerHTML={{
              __html: record?.coreCompetency?.ratingScale,
            }}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Core Competencies">
          <List
            bordered={true}
            size="large"
            dataSource={record.coreCompetencyList}
            renderItem={item => (
              <List.Item>{translate(item.name, "en-US")}</List.Item>
            )}
          />
        </Card>
      </Col>
      <Col span={record.type !== 3 ? 24 : 0}>
        <Card title="Additional Score">
          <List
            bordered={true}
            size="large"
            dataSource={record.scoreAdjustmentList}
            renderItem={item => (
              <List.Item>{translate(item.name, "en-US")}</List.Item>
            )}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Comment">
          <CommentTable dataSource={commentDataSource} />
        </Card>
      </Col>
      {record.isNeedGoalSetting ? (
        <Col span={24}>
          <Card title="Goal Setting">
            <Card title="GuideLine">
              <div dangerouslySetInnerHTML={{ __html: guideline }} />
            </Card>
            <Card title="指導方針">
              <div dangerouslySetInnerHTML={{ __html: guidelineCn }} />
            </Card>
            <List
              bordered={true}
              size="large"
              dataSource={record.goalSettingList}
              renderItem={item => (
                <List.Item>{translate(item.name, "en-US")}</List.Item>
              )}
            />
          </Card>
        </Col>
      ) : null}
      <Col>
        <Button
          shape="round"
          onClick={() => {
            window.history.back()
          }}
        >
          Back
        </Button>
      </Col>
    </Row>
  )
}

const ViewTemplate = ({ location }) => {
  const { state = {} } = location
  const [isLoad, setIsLoad] = useState(false)
  const [record, setRecord] = useState(null)

  useEffect(() => {
    const initPage = async id => {
      const client = new AppraisalTemplateClient()
      try {
        let result = await client.get2(id)
        if (!!result.coreCompetencyDefinitionSetId) {
          const coreClient = new CoreCompetencyDefinitionSetClient()
          const coreResult = await coreClient.get2(
            result.coreCompetencyDefinitionSetId
          )
          result = {
            ...result,
            coreCompetency: coreResult,
            coreCompetencyList: coreResult.coreCompetencyDefinitions,
            scoreAdjustmentList: coreResult.scoreAdjustmentDefinitions,
          }
        }
        if (!!result.goalSettingDefinitionSetId) {
          const goalClient = new GoalSettingDefinitionSetClient()
          const goalRes = await goalClient.get2(
            result.goalSettingDefinitionSetId
          )
          result = {
            ...result,
            goalSetting: goalRes,
            goalSettingList: goalRes.goalSettingDefinitions,
          }
        }

        setRecord(result)
        setIsLoad(true)
      } catch (err) {
        let errmsg = JSON.parse(err.response)
        showError(errmsg.errors)
      }
    }

    if (state.id !== null) {
      initPage(state.id)
    } else {
      window.history.back()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <Seo title="Template Overview" />
      <PageHeader
        onBack={() => window.history.back()}
        title="Appraisal Template Overview"
        ghost={false}
      />
      {isLoad ? <Template record={record} /> : <Loading />}
    </MainLayout>
  )
}

export default ViewTemplate
